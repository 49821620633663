<script setup>
// import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import { onMounted } from "vue";
onMounted(() => {
  KTComponents.init();
  KTThemeMode.init();
});
</script>

<template>
  <!--begin::Form-->
  <div class="d-flex flex-column flex-root" id="kt_app_root">
    <video autoplay loop muted id="bg-video">
      <source src="/video/login_back.mp4" type="video/mp4" />
      <!-- Include additional source elements for other video formats if needed -->
    </video>
    <slot />
  </div>
  <!--end::Form-->
  <!--end::Authentication - Sign-in-->
</template>
